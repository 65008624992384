import GuidUtils from '@/shared/utils/guid'
import QuestionType from '@/shared/enums/questionType'

export default class Question {
  constructor({
    id,
    anamneseId,
    question,
    additionalQuestion,
    questionType,
    order,
    anamneseQuestionAlternatives
  } = {}) {
    this.id = id || GuidUtils.emptyGuid,
    this.anamneseId = anamneseId,
    this.question = question,
    this.additionalQuestion = additionalQuestion
    this.questionType = questionType
    this.questionTypeText = this.getQuestionType(questionType)
    this.order = order
    this.anamneseQuestionAlternatives = anamneseQuestionAlternatives
    this.anamneseQuestionAlternativesText = anamneseQuestionAlternatives ? anamneseQuestionAlternatives.join(' | ') : ''
  }

  getQuestionType(questionType) {
    const questionTypeValues = Object.values(QuestionType)
    const questionTypeObj = questionTypeValues.find(element => element.id === questionType)

    if(!questionTypeObj)
      return ''

    return questionTypeObj.name
  }
}
