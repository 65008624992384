import SocialProjectManager from '../../clients/abdaSocialProjectManager'
import { merge } from 'lodash'

const resourceName = 'anamnesequestion'

const GetByAnamneseId = (anamneseId, pagination) => SocialProjectManager.get(`${resourceName}/anamnese/${anamneseId}`, {
  params: merge(pagination)
})
const GetById = id => SocialProjectManager.get(`${resourceName}/${id}`)

const Create = data => SocialProjectManager.post(resourceName, data)

const Update = data => SocialProjectManager.put(`${resourceName}/${data.id}`, data)

const Delete = id => SocialProjectManager.delete(`${resourceName}/${id}`)

const AnamneseQuestionRepository = {
  GetByAnamneseId,
  GetById,
  Create,
  Update,
  Delete
}

export default AnamneseQuestionRepository