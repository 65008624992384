import AnamneseQuestionRepository from '@/shared/http/repositories/socialProject/anamnese-question'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import Pagination from '@/components/layout/Pagination/pagination.vue'
import fab from 'vue-fab'
import { IsSuccessCode } from '@/shared/utils/API'
import {toast, loading } from '@/shared/utils/UIHelper'
import QuestionModel from '@/shared/models/question'
import { noValue } from '@/shared/filters/NoValue'

export default {
  components: {
    [BreadCrumb.name]: BreadCrumb,
    [Pagination.name]: Pagination,
    fab
  },

  data: () => ({
    questions: [],
    labelSearch: 'Grupos de Anamnese',
    showDeleteModal: false,
    id: null,
    searchText: null,
    emptyQuestion: 'Não há questões cadastradas.',
    fields: [
      {
        key: 'order',
        label: 'ORDEM',
        class: 'left-header-border text-center',
      },
      {
        key: 'questionTypeText',
        label: 'TIPO DA QUESTÃO',
        class: 'middle-header text-center',
      },
      {
        key: 'question',
        label: 'QUESTÃO',
        class: 'middle-header text-center',
      },
      {
        key: 'anamneseQuestionAlternativesText',
        label: 'OPÇÕES',
        class: 'middle-header text-center',
        formatter: noValue
      },
      {
        key: 'action',
        label: 'AÇÕES',
        class: 'right-header-border text-center'
      }
    ],
    pagination: {
      count: 1,
      limit: 10,
      index: 1
    }
  }),

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Anamnese',
            href: this.$router.resolve({ name: 'Anamnese' }).href
          },
          {
            text: 'Questões',
            active: true
          }
        ]
      }
    }
  },

  created() {
    this.getByAnamneseId()
  },

  methods: {
    getByAnamneseId() {
      loading.push()
      AnamneseQuestionRepository.GetByAnamneseId(this.$route.params.anamneseid, this.pagination)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.questions = res.data.data.map(question => {
            return new QuestionModel(question)
          })

          this.pagination = {
            count: res.data.count,
            limit: res.data.pagingLimit,
            index: res.data.pagingIndex
          }

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar questões', 'ERRO')
        })
    },

    onDelete() {
      loading.push()
      AnamneseQuestionRepository.Delete(this.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.getByAnamneseId()
          this.showDeleteModal = false
          toast.success('Questão excluída com sucesso!', 'EXCLUSÃO DE QUESTÃO')
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao excluir questão', 'ERRO')
        })
    },

    selectRow(id) {
      this.showDeleteModal = true
      this.id = id
    }
  }
}
